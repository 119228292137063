import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { abstract, fbAppID } from '@/constant/seo';

export const clearMeta = () => {
  console.log(`[head] clear head`);

  useHead({
    meta: [
      { name: 'description', content: abstract },
      { property: 'fb:app_id', content: fbAppID },
      { property: 'og:title', content: null },
      { property: 'og:site_name', content: null },
      { property: 'og:description', content: null },
      { property: 'og:url', content: null },
      { property: 'og:type', content: null },
      { property: 'og:image', content: null },
      { property: 'og:locale', content: null },
      { property: 'product:price.amount', content: null },
      { property: 'product:price.currency', content: null },
    ],
    script: [computed(() => ({ type: 'application/ld+json', children: null }))],
  });
};

/**
 * Products LD Json
 * @param products
 * @param hasStock
 * @returns {*}
 */
export const convertProductsToLDJson = ({ products, hasStock = false }) => {
  const { VITE_BASE_URL: baseUrl } = import.meta.env;
  return products.map((product) => {
    // 建立基本產品結構
    const productLd = {
      '@type': 'Product',
      name: product.name,
      image: product.image,
      url: `${baseUrl}${product.url}`,
      sku: product.id,
    };

    productLd.offers = {
      '@type': 'Offer',
      seller: { '@type': 'Organization', name: '誠品線上' },
      // 如果需要包含庫存信息，添加 offers 屬性
      ...(hasStock && {
        availability: product.stock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
        url: `${baseUrl}${product.url}`,
        ...(product.price && {
          price: product.price,
          priceCurrency: product.currency || 'TWD',
        }),
      }),
    };

    return productLd;
  });
};
